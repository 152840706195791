<template>
  <div class="home">
    <v-carousel
      hide-delimiters
      hide-arrows
      :left-control-icon="false"
      :right-control-icon="false"
    >
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        :src="item.src"
        reverse-transition="fade-transition"
        transition="fade-transition"
      ></v-carousel-item>
    </v-carousel>
    <v-card>
      <v-card-title class="display-3">
        Mittagsmenü
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        :items-per-page="-1"
        ><template v-slot:item.speise="{ item }">
          <b>{{ item.speise }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template></v-data-table
      >
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Mittagsmenuekarte',
  data() {
    return {
      items: [
        {
          src: require('../assets/images/mittagsmenueteller.jpg'),
        },
      ],
      search: '',
      headers: [
        {
          text: 'Nummer',
          align: 'start',
          sortable: false,
          value: 'nummer',
        },
        { text: 'Speise', value: 'speise' },
        { text: 'Allergene', value: 'allergene' },
        { text: 'Preis', value: 'preis' },
      ],
      desserts: [
        {
          nummer: 'M1',
          speise: 'Toridon (Hühnerfleisch gegrillt mit Teriyaki Sauce)',
          allergene: '(A, F)',
          preis: '7,70 €',
        },
        {
          nummer: 'M2',
          speise: 'Gebratener Tofu mit Gemüse',
          allergene: '(A, F)',
          preis: '8,20 €',
        },
        {
          nummer: 'M3',
          speise: 'Gebratenes Rindfleisch mit Zwiebeln',
          allergene: '(F)',
          preis: '8,50 €',
        },
        {
          nummer: 'M4',
          speise: 'Gemischter gebratener Fischteller mit Gemüse',
          allergene: '(D)',
          preis: '8,50 €',
        },
        {
          nummer: 'M5',
          speise: 'Nudeln mit Rindfleisch oder Hühnerfleisch oder Garnelen',
          allergene: '(A, B, F)',
          preis: '7,50 €',
        },
        {
          nummer: 'M6',
          speise:
            'Gebratener Reis mit Rindfleisch oder Hühnerfleisch oder Garnelen',
          allergene: '(A, B, C, F)',
          preis: '7,50 €',
        },
        {
          nummer: 'M7',
          speise: 'Sushi Set klein (7 Sushi und 3 Maki)',
          allergene: '(C, D)',
          preis: '8,50 €',
        },
        {
          nummer: 'M8',
          speise: 'Maki im Set (15 Stk.)',
          allergene: '(C, D, N)',
          preis: '8,50 €',
        },
        {
          nummer: 'M9',
          speise: 'Knusprige Ente',
          allergene: '(A, F)',
          preis: '8,80 €',
        },
        {
          nummer: 'M10',
          speise: 'Hühnerfleisch mit Sesam, paniert',
          allergene: '(C, F, N)',
          preis: '8,00 €',
        },
        {
          nummer: 'M11',
          speise: 'Gebratenes Hühnerfleisch mit Gemüse',
          allergene: '(F)',
          preis: '8,00 €',
        },
        {
          nummer: 'M12',
          speise: 'Ente mit gegrillten Fleischtaschen',
          allergene: '(A, F)',
          preis: '8,90 €',
        },
        {
          nummer: 'M13',
          speise: 'Knuspriges Huhn',
          allergene: '(A, F)',
          preis: '8,40 €',
        },
        {
          nummer: 'M14',
          speise: 'Hühnerfleisch mit Paprika und Ente (scharf)',
          allergene: '(A, F)',
          preis: '8,90 €',
        },
        {
          nummer: 'M15',
          speise: 'Gegerilltes Lachssteak mit Gemüse',
          allergene: '(A, D)',
          preis: '9,20 €',
        },
        {
          nummer: 'M16',
          speise: 'Süß-saures Hühnerfleisch, paniert',
          allergene: '(A, C)',
          preis: '8,30 €',
        },
        {
          nummer: 'M17',
          speise: 'Hühnerfleisch mit Gemüse und Erdnüsse',
          allergene: '(E, F)',
          preis: '8,30 €',
        },
        {
          nummer: 'M18',
          speise: 'Gebratener Lachs mit Gemüse und Teriyaki Sauce',
          allergene: '(A, D)',
          preis: '8,60 €',
        },
        {
          nummer: 'M19',
          speise: 'Acht Schätze (scharf)',
          allergene: '(A, F)',
          preis: '8,90 €',
        },
        {
          nummer: 'M20',
          speise: 'Hühnerfleisch mit Bambus und Pilzen',
          allergene: '(F)',
          preis: '8,50 €',
        },
        {
          nummer: 'M21',
          speise: 'Nudeln mit knuspriger Ente oder knusprigem Huhn',
          allergene: '(A, F)',
          preis: '9,00 €',
        },
        {
          nummer: 'M22',
          speise: 'Rindfleisch mit Bambus und Pilzen',
          allergene: '(A, F)',
          preis: '8,80 €',
        },
        {
          nummer: 'M23',
          speise: 'Rindfleisch nach Sichuan Art (scharf)',
          allergene: '(A, F)',
          preis: '8,80 €',
        },
        {
          nummer: 'M24',
          speise: 'Rindfleisch nach Chop Suey',
          allergene: '(A, F)',
          preis: '8,80 €',
        },
        {
          nummer: 'M25',
          speise: 'Rindfleisch mit Thai Sauce',
          allergene: '(A, F)',
          preis: '8,90 €',
        },
        {
          nummer: 'M26',
          speise: 'Hühnerfleisch Chop Suey',
          allergene: '(A, F)',
          preis: '8,50 €',
        },
        {
          nummer: 'M27',
          speise: 'Hühnerfleisch nach Sichuan Art (scharf)',
          allergene: '(A, F)',
          preis: '8,50 €',
        },
        {
          nummer: 'M28',
          speise: 'Hühnerfleisch mit Thai Sauce',
          allergene: '(A, F)',
          preis: '8,50 €',
        },
        {
          nummer: 'M29',
          speise:
            'Gebratener Eierreis mit knuspriger Ente oder knusprigem Huhn',
          allergene: '(A, C, F)',
          preis: '9,00 €',
        },
        {
          nummer: 'M30',
          speise: 'Gebratenes Hühnerfleisch mit Teriyaki Sauce',
          allergene: '(A, F)',
          preis: '8,50 €',
        },
        {
          nummer: 'M31',
          speise: 'Gegrillte Fleischtaschen mit Teriyaki Sauce (10 Stk.)',
          allergene: '(A, F, N)',
          preis: '8,70 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
