<template>
  <div class="home">
    <v-carousel hide-delimiters cycle height="550px">
      <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src">
        <v-row class="fill-height" align="center" justify="center">
          <div class="text-h1 white--text">
            {{ item.title }}
          </div>
        </v-row></v-carousel-item
      >
    </v-carousel>
    <!-- <div class="container">
      <backtospeisekarte></backtospeisekarte>
    </div> -->
    <v-card>
      <v-card-title class="display-3">
        Sushi
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.speise="{ item }">
          <b>{{ item.speise }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template></v-data-table
      >
    </v-card>
  </div>
</template>

<script>
// import Backtospeisekarte from './Backtospeisekarte.vue';
export default {
  // components: { Backtospeisekarte },
  name: 'Sushi',
  data() {
    return {
      items: [
        {
          src: require('../assets/images/tatargurkenrollfront.png'),
        },
        {
          src: require('../assets/images/tatargurkenrollvogel.png'),
        },
        {
          src: require('../assets/images/lachskaviarsushifrontal.png'),
        },
        {
          src: require('../assets/images/lachskaviarsushioben.png'),
        },
        // {
        //   src: require('../assets/images/thunfischkaviarsushiflambiertseitlich.png'),
        // },
        {
          src: require('../assets/images/thunfischkaviarsushiflambiertfrontal.png'),
        },
        {
          src: require('../assets/images/lachskaviarsushiflambiertfrontal.png'),
        },
        {
          src: require('../assets/images/lachskaviarsushiflambiertoben.png'),
          title: '',
        },
        {
          src: require('../assets/images/sushidonutsoben.png'),
          title: '',
        },
        {
          src: require('../assets/images/sushitorteoben.png'),
          title: '',
        },
        {
          src: require('../assets/images/sushioben.jpg'),
          title: '',
        },
        {
          src: require('../assets/images/sushiganzoben.jpg'),
          title: '',
        },
      ],
      search: '',
      headers: [
        {
          text: 'Nummer',
          align: 'start',
          sortable: false,
          value: 'nummer',
        },
        { text: 'Speise', value: 'speise' },
        { text: 'Inhalt', value: 'inhalt' },
        { text: 'Anzahl', value: 'anzahl' },
        { text: 'Allergene', value: 'allergene' },
        { text: 'Preis', value: 'preis' },
      ],
      desserts: [
        // {
        //   nummer: 'S6',
        //   speise: 'Sushi Set mini',
        //   inhalt: '',
        //   anzahl: '(5 Stk. u. 3 Maki)',
        //   allergene: '(B, D, R)',
        //   preis: '8,30 €',
        // },
        {
          nummer: 'S7',
          speise: 'Sushi Set klein',
          inhalt: '',
          anzahl: '(7 Stk. u. 3 Maki)',
          allergene: '(B, C, D, R)',
          preis: '9,60 €',
        },
        {
          nummer: 'S8',
          speise: 'Sushi Set mittel',
          inhalt: '',
          anzahl: '(9 Stk. u. 3 Maki)',
          allergene: '(B, C, D, R)',
          preis: '10,50 €',
        },
        {
          nummer: 'S9',
          speise: 'Sushi Set groß',
          inhalt: '',
          anzahl: '(11 Stk. u. Maki)',
          allergene: '(B, C, D, R)',
          preis: '11,50 €',
        },
        {
          nummer: 'S11',
          speise: 'Lachs Sushi klein',
          inhalt: '',
          anzahl: '(7 Stk. u. 3 Maki)',
          allergene: '(A, F, D)',
          preis: '9,60 €',
        },
        {
          nummer: 'S12',
          speise: 'Lachs Sushi mittel',
          inhalt: '',
          anzahl: '(9 Stk. u. 3 Maki)',
          allergene: '(A, F, D)',
          preis: '10,50 €',
        },
        {
          nummer: 'S22',
          speise: 'Lachs Sushi groß',
          inhalt: '',
          anzahl: '(11 Stk. u. 3 Maki)',
          allergene: '(A, F, D)',
          preis: '11,50 €',
        },
        {
          nummer: 'S13',
          speise: 'Thunfisch & Lachs Sushi',
          inhalt: '',
          anzahl: '(10 Stk.)',
          allergene: '(A, F, D)',
          preis: '11,50 €',
        },
        {
          nummer: 'S10',
          speise: 'Thunfisch Sushi klein',
          inhalt: '',
          anzahl: '(7 Stk. u. 3 Maki)',
          allergene: '(A, F, D)',
          preis: '11,00 €',
        },
        {
          nummer: 'S14',
          speise: 'Thunfisch Sushi mittel',
          inhalt: '',
          anzahl: '(9 Stk. u. 3 Maki)',
          allergene: 'A, F, D',
          preis: '12,00 €',
        },
        {
          nummer: 'S29',
          speise: 'Thunfisch Sushi groß',
          inhalt: '',
          anzahl: '(11 Stk. u. 3 Maki)',
          allergene: 'A, F, D',
          preis: '12,70 €',
        },
        {
          nummer: 'S15',
          speise: 'Thunfisch-Lachs-Garnelen Sushi',
          inhalt: '',
          anzahl: '(9 Stk.)',
          allergene: 'D',
          preis: '11,50 €',
        },
        {
          nummer: 'S16',
          speise: 'Vegetarische Sushi',
          inhalt: '',
          anzahl: '(9 Stk.)',
          allergene: '',
          preis: '9,90 €',
        },
        {
          nummer: 'S17',
          speise: 'Chirashi Sushi',
          inhalt: '',
          anzahl: '/',
          allergene: 'B, D',
          preis: '12,30 €',
        },
        {
          nummer: 'S18',
          speise: 'Lachs Sushi mit flambiertem Lachs & Teriyaki',
          inhalt: '',
          anzahl: '(10 Stk.)',
          allergene: 'D',
          preis: '12,00 €',
        },
        {
          nummer: 'S19',
          speise: 'Tempura Sushi ',
          inhalt: '(Sushi mit gebackenem Hühnerfleisch und Sesam)',
          anzahl: '(10 Stk.)',
          allergene: 'A, D, F, N',
          preis: '12,30 €',
        },
        {
          nummer: 'S20',
          speise: 'Lachs Sushi Rollen',
          inhalt:
            '(Reis mit Lachs umwickelt und mit Mayonnaise,Garnele und Avocado überdeckt)',
          anzahl: '(10 Stk.)',
          allergene: 'A, F, D, R, B',
          preis: '13,80 €',
        },
        {
          nummer: 'S21',
          speise: 'Avocado-Lachs Kugeln ',
          inhalt:
            '(Reis und Fisch mit Avocado ummantelt und mit Kavier überdeckt)',
          anzahl: '(3 Stk.)/(1 Stk.)',
          allergene: 'A, F, D, R, B',
          preis: '15,90 €/6,00 €',
        },
        {
          nummer: 'S23',
          speise: 'Sushi Donuts ',
          inhalt:
            '(Sushi-Reis, Thunfisch, Lachs, Garnelen, Avocado, Mayonnaise und Sesam)',
          anzahl: '(3 Stk.)/(1 Stk.)',
          allergene: 'A, C, D, F, N',
          preis: '13,50 €/5,50 €',
        },
        {
          nummer: 'S24',
          speise: 'Sushi Torte',
          inhalt:
            '(Sushi-Reis, Thunfisch, Lachs, Kaviar, Avocado, Mayonnaise und Gurke)',
          anzahl: '(1 Stk.)',
          allergene: 'A, C, D, F',
          preis: '13,50 €',
        },
        {
          nummer: 'S25',
          speise: 'Lachs Kaviar Sushi',
          inhalt:
            '(Lachs Sushi mit zwei verschiedenen Kaviarsorten und Jungzwiebel überdeckt)',
          anzahl: '(10 Stk.)',
          allergene: 'A, F, D',
          preis: '13,40 €',
        },
        {
          nummer: 'S26',
          speise: 'Flambiertes Lachs Kaviar Sushi ',
          inhalt:
            '(Flambiertes Lachs Sushi mit zwei verschiedenen Kaviarsorten und Jungzwiebel überdeckt)',
          anzahl: '(10 Stk.)',
          allergene: 'A, F, D',
          preis: '13,90 €',
        },
        {
          nummer: 'S27',
          speise: 'Flambiertes Thunfisch Kaviar Sushi ',
          inhalt:
            '(Flambiertes Thunfisch Sushi mit zwei verschiedenen Kaviarsorten, Mayonnaise und Jungzwiebel überdeckt)',
          anzahl: '(10 Stk.)',
          allergene: 'A, F, D',
          preis: '14,90 €',
        },
        {
          nummer: 'S28',
          speise: 'Tatar Gurken Roll',
          inhalt:
            '(Sushi-Reis mit Gurke umwickelt, zwei verschiedene Kaviarsorten, Thunfisch Tatar, Garnelen Tatar, Lachs Tatar, Mayonnaise)',
          anzahl: '(9 Stk.)',
          allergene: 'A, B, F, D, R',
          preis: '13,50 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
